import React from 'react';

import { Box, Typography, Button } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import { useNavigate } from 'react-router-dom';

function HeroBanner() {
    const navigate = useNavigate();
  
    const handleSubmit = async (e) => {
      navigate('/signup');
    };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        textAlign: 'center',
      }}
    >
      {/* Content */}
      <Box sx={{ paddingBottom: "10px", }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: '6vw', color: '#338395'}}>
          Unlock the power of your data with HeroParty
        </Typography>
        <Button 
          onClick={handleSubmit} 
          variant="contained"
          endIcon={<SendIcon />}
          sx={{
            backgroundColor: '#dd9e85', 
            color: '#393b49',
          }}
          >
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#393b49'}}>Join Early Access</Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default HeroBanner;
