// src/components/About.js
import React from 'react';

import { 
    Typography,
    Card,
    CardMedia,
    Stack,
    CardContent,
    Link, 
    Divider
} from '@mui/material';

import usImage from '../assets/us.jpeg';

const About = () => {
    return (
        <div>
            <Stack spacing={2}>
                <Card sx={{ backgroundColor: '#338395', color: 'white'}}>
                    <Typography variant="h4" sx={{ textAlign: 'center' }}>
                        Our Story
                    </Typography>
                </Card>
                <Card sx={{ backgroundColor: '#393b49', color: '#c0f7fc'}}>
                    <CardContent>
                        <Typography variant="h5" sx={{ textAlign: 'center' }}>
                            What is data but a point in a story.
                        </Typography>
                        <Typography variant="h4" sx={{ textAlign: 'center' }}>
                            <b>Own Your Story</b>.
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{ backgroundColor: '#338395', color: 'white'}}>
                    <CardContent>
                        <Typography variant="h5" sx={{ textAlign: 'center' }}>Our mission</Typography>
                        <Typography variant="p" sx={{ textAlign: 'center' }}>
                            <b>Why do you need a Data Hero? </b>
                            We want to make your life easier by unlocking the data that companies hold on you by utilising GDPR's Subject Access Request and by you providing data
                            to us via first party means.  
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{ backgroundColor: '#dd9e85', color: '#393b49'}}>
                    <CardMedia
                        component="img"
                        height="200px"
                        image={usImage}
                    />
                    <CardContent>
                        <Typography variant="h5" sx={{ textAlign: 'center' }}>
                            Who are we? 
                        </Typography>
                        <Typography variant="p" sx={{ textAlign: 'center' }}>
                            <Link href="https://uk.linkedin.com/in/james-thornberry">James</Link> and <Link href="https://uk.linkedin.com/in/elliot-dines">Elliot</Link> are seasoned data professionals with a wealth of experience from leading UK organisations like the BBC and MoneySuperMarket. Their journey began at Just Eat, where they first crossed paths and discovered a shared passion for turning data into actionable insights.
                            <Divider sx={{ my: 1 }}/>
                            With expertise in Analytics and Data Engineering, they bring a deep understanding of how to harness data to drive business growth and innovation.
                        </Typography>
                    </CardContent>
                </Card>
             </Stack>
        </div>
    );
};

export default About;
