import React from 'react';

import HeroBanner from './Hero';

import { 
  Typography,
  Card, 
  CardContent, 
  CardMedia, 
  Box, 
  Stack, 
  Divider, 
  Chip, 
} from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';

import coupleImage from '../assets/couple.png';
import passportImage from '../assets/passport.png';
import headphonesImage from '../assets/headphones.png';


const LandingPage = () => {  
  return (
    <div>
      <Stack direction="column" spacing={1}>
        <HeroBanner/>
        <Card sx={{ fontWeight: 'bold', backgroundColor: 'inherit', color: '#338395', textAlign: 'center'}} variant="outlined">
          <CardContent>
            <Typography variant="h4">Collect</Typography>
            <Typography variant="p">
              We identify and collect <b>your data</b> from companies using a range of <u>Zero Data</u> methods like <u>SARs</u>
            </Typography>
            <Divider><Chip sx={{ color: '#338395'}} label="*" size="small" /></Divider>
            <Typography variant="h4">Store</Typography>
            <Typography variant="p">
              Your data is then securely stored and anything identifying you is encrypted
            </Typography>
            <Divider><Chip sx={{ color: '#338395'}} label="*" size="small" /></Divider>
            <Typography variant="h4">Value</Typography>
            <Typography variant="p">
              We then use our combined 20 years of experience to provide industry professional standard analytics to our users
            </Typography>
          </CardContent>
        </Card>
      </Stack>
      <br/>
      <Card sx={{ display: 'flex', backgroundColor: '#338395', color: 'white'}}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography variant="h4">
              Your Data Working For You
            </Typography>
            <Typography variant="p">
              We unlock the power of your data to benefit you. Using the data you're entitled to, we perform world-class analytics to help you save money and maximize your time.
            </Typography>
            <Divider sx={{ my: 1 }}/>
            <Typography variant="p">
              From everyday groceries to special celebrations, we provide personalised insights and recommendations to enhance what matters most to you.
            </Typography>
          </CardContent>
        </Box>
        <CardMedia
          component="img"
          sx={{ width: "15vw" }}
          image={coupleImage}
          alt="Live from space album cover"
        />
      </Card>
      <br/>
      <Card sx={{ display: 'flex', backgroundColor: '#dd9e85', color: '#393b49'}}>
        <CardMedia
          component="img"
          sx={{ width: "15vw" }}
          image={passportImage}
          alt="Live from space album cover"
        />
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography variant="h4">
              Verification
            </Typography>
            <Typography variant="p">
              We create your digital identity, giving you control over your online information and the ability to verify your identity with other companies.
            </Typography>
            <Divider sx={{ my: 1 }}/>
            <Typography variant="p">
              Easily update your details across services—no need to notify multiple organisations. You decide which companies or government services we share your information with.
            </Typography>
          </CardContent>
        </Box>
      </Card>        
      <br/>
      <Card sx={{ display: 'flex', backgroundColor: '#393b49', color: '#c0f7fc'}}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <CardContent sx={{ flex: '1 0 auto' }}>
            <Typography variant="h4">
              Portability
            </Typography>
            <Typography variant="p">
              Switching services shouldn't mean starting over. At HeroParty, we eliminate barriers by seamlessly rebuilding your profiles, playlists, and accounts, making transitions smooth while preserving your hard work.
            </Typography>
          </CardContent>
        </Box>
        <CardMedia
          component="img"
          sx={{ width: "15vw" }}
          image={headphonesImage}
          alt="Live from space album cover"
        />
      </Card>        
      <br/>
      <Stack direction="row">
        <Card variant="outlined" sx={{ backgroundColor: 'inherit', color: '#338395', fontWeight: 'bold'}}>
          <CardContent sx={{ textAlign: 'center'}}>
            <PersonAddAlt1Icon sx={{ fontSize: '50px' }} />
            <Typography sx={{fontWeight: 'bold'}} variant="h6">Create and verify your account</Typography>
          </CardContent>
        </Card>
        <Card variant="outlined" sx={{ backgroundColor: 'inherit', color: '#338395', fontWeight: 'bold'}}>
          <CardContent sx={{ textAlign: 'center' }}>
            <MailOutlineIcon sx={{ fontSize: '50px'}} />
            <Typography sx={{fontWeight: 'bold'}} variant="h6" >Tell us what services you use</Typography>
          </CardContent>
        </Card>
        <Card variant="outlined" sx={{ backgroundColor: 'inherit', color: '#338395' }}>
          <CardContent sx={{ textAlign: 'center' }}>
            <MobileFriendlyIcon sx={{ fontSize: '50px'}} />
            <Typography sx={{fontWeight: 'bold'}} variant="h6" >Sit back and wait for us to send you your insights</Typography>
          </CardContent>
        </Card>
      </Stack>
    </div>
  );
};

export default LandingPage;
