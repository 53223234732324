import React from 'react';
import { supabase } from './supabaseClient';
import { Container, Card, CardContent, Box,  Button, Typography, Stack } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';

function SignUpPage() {

  const handleSignUp = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
    });
    if (error) console.error(error);
  };


  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Stack spacing={1}>
          <Card sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#393b49', 
            color: '#c0f7fc'
          }}>
          </Card>
          <Card variant="outlined" sx={{ width: 400 , backgroundColor: 'inherit', color: '#338395' }}>
            <CardContent>
              <Typography variant="h4" align="center" sx={{ fontWeight: 'bold'}} gutterBottom>
                Create an account
              </Typography>
              <Button
                onClick={handleSignUp}
                startIcon={<GoogleIcon />} 
                type="submit"
                fullWidth
                variant="outlined"
                sx={{
                  marginTop: 2, 
                  borderColor: '#338395',
                  color: '#338395',
                  fontWeight: 'bold', 
                  fontSize: '20px'
                }}
              > 
                Sign Up with Google
              </Button>
            </CardContent>
          </Card>
        </Stack>
      </Box>
    </Container>
  );
}

export default SignUpPage;
