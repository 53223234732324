import React from "react";
import LandingPage from "./components/LandingPage";
import About from "./components/About";
import BlogPage from './components/Blogs';
import SignUpPage from './components/SignUpPage'; 

import { 
  Container,
  Typography,
  Link, 
  Stack, 
  Box, 
  AppBar, 
  Toolbar, 
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

const theme = createTheme({
  typography: {
    fontFamily: "'DM Serif Text', serif",
  },
});

function App() {  
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" elevation={0}>
        <Toolbar sx={{ backgroundColor: '#393b49', color: '#c0f7fc'}}>
          <Typography sx={{ fontWeight: 'bold',}} variant="h4"><Link color="inherit" underline="none" href="/">HeroParty</Link></Typography>
          <Stack sx={{px: 1.5,}} direction="row" spacing={2}>
            <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold', }}><Link color="inherit" underline="none" href="/signup">Sign Up</Link></Typography>
            <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold',}}> <Link color="inherit" underline="none" href="/blogs">Blogs</Link></Typography>
            <Typography variant="h6" sx={{ textAlign: 'center', fontWeight: 'bold',}}> <Link color="inherit" underline="none" href="/about">About</Link></Typography>
          </Stack>
        </Toolbar>
      </AppBar>
      <Box sx={{
        width: '90%',        // Span 80% of the screen width
        margin: 'auto',
      }}> 
        <Router>
          <br/>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<About />} />
            <Route path='/blogs' element={<BlogPage />} />
            <Route path='/signup' element={<SignUpPage />} />
          </Routes>
        </Router>
      </Box>
      
      <br/>
      <Box 
        component="footer"
        sx={{
          py: 3,                     // Padding on top and bottom
          px: 2,                     // Padding on left and right
          mt: 'auto',                // Pushes the footer to the bottom
          backgroundColor: "#393b49", 
          color: "white"
        }}>
        <Container>
          <Typography variant="body1">
            HeroParty {new Date().getFullYear()} 
          </Typography>
          <Stack direction="row" spacing={2}>
            <Link color="inherit" href="/signup">Sign Up</Link>
            <Link color="inherit" href="/blogs">Blogs</Link>
            <Link color="inherit" href="/about">Our Story</Link>
            <Link color="inherit" href="mailto:ceo.heroparty@gmail.com">Contact Us</Link>
          </Stack>
        </Container>
      </Box>
    </ThemeProvider>
    
  );
}

export default App;